<template>
	<div class="popup_sub_container add_contact_adj" style="min-height:750px;">
		<h2>ADDRESS HISTORY</h2>
		<i class="closeAddContact" @click="closeModal">X</i>
		<ValidationObserver ref="observer" v-slot="{ invalid }" slim>
		<section>
			<table style="width:100%;position: sticky;top: 10px;">
				<thead style="width: 100%;background: #eee;color: black;cursor: pointer;">
					<tr>
						<th :style="key == showFilter ? 'padding:10px;width:25%;text-transform: capitalize;background:#004a7c;color:white' : 'padding:10px;width:25%;text-transform: capitalize;'" @click="filter(key)" v-for="(value, key) in showAddressList">{{ key }}</th>
					</tr>
				</thead>
			</table>
			<table class="address-list" style="width:100%">
				<thead>
					<tr>
						<th>Booking Ref</th>
						<th>Status</th>
						<th>Request Date</th>
						<th>Note</th>
					</tr>
				</thead>
				<tbody>
					<tr v-if="!showAddressList[showFilter].length">
						<td colspan="20"><center>No List</center></td>
					</tr>
					<template v-if="showAddressList[showFilter]">
					<tr v-for="value in showAddressList[showFilter]">
						<td>{{value.booking_prefix}}{{value.booking_id}}</td>
						<td>{{value.status}}</td>
						<td>{{value.request_date}}</td>
						<td>
							{{value.delivered_status_raw ? value.delivered_status_raw.note : '' }}
						</td>
					</tr>
					</template>
				</tbody>
			</table>
		</section>
		</ValidationObserver>
		<section class="centered">
			<a class="link_bt bt_cancel" @click="closeModal">Cancel</a>
		</section>
	</div>
</template>
<script>
export default {
	name: "AddressHistoryList",
    props: ['showAddressList'],
	data() {
		return {
			showFilter:'success'
		};
	},
	created() {
		console.log(this.showAddressList)
	},
	methods: {
		closeModal() {
			this.modalStatus = false;
			this.$emit("closeModal", this.modalStatus);
		},
		filter(key) {
			this.showFilter = key
		}
	},
};
</script>
<style lang="scss">
.address-list {
	margin-top:10px;
	thead {
		width: 100%;
		background: #004a7c;
		color: white;
		cursor: pointer;
		position:sticky;
		top:50px;
	}
	tbody {
		tr {
			td {
				border:1px solid black;
			}
		}
		
		tr:hover {
			background-color: #dedede;
		}
	}
}
.popup_sub_container {
	padding: 20px;
	width: 100%;
	margin: auto;

	background: #fff;
}

.popup_sub_container>section {
	padding: 20px 0 0 0;
}

h3.sub_head {
	font-size: 20px;
	font-weight: 600;
	padding: 0;
}

.preferred_hide {
	display: none;
}

.chk_prefered {
	width: 20px;
	height: 20px;
	display: inline-block;
	margin: 0 20px;
}

.chk_prefered:checked+hr+.preferred_hide {
	display: block;
}

.title_image {
	display: block;
	margin: auto;
}

.form_section1 {
	width: 100%;
	height: auto;
	padding: 20px;
	background: #ececec;
	margin: 20px 0;
}

i.closeAddContact {
	width: 30px;
	height: 30px;
	background: #000;
	color: #06A5ED;
	font-size: 14px;
	text-align: center;
	line-height: 30px;
	border-radius: 100px;
	display: block;
	font-style: normal;
	font-weight: bold;
	position: relative;
	float: right;
	right: 0;
	top: -60px;
	cursor: pointer;
}
</style>
<style scoped>
/*this fix is for table with 2 column only, to keep same width*/
.form_section1 table tr td {
	width: 50%;
}
</style>