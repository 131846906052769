<template>
	<div class="popup_sub_container add_contact_adj">
		<h2>CHANGE PICKUP ADDRESS</h2>
		<i class="closeAddContact" @click="closeModal">X</i>
		<ValidationObserver ref="observer" v-slot="{ invalid }" slim>

			<section>

				<div class="form_section1">
					<table>
						<tr>
							<td>
								<ValidationProvider name="name" rules="required|max:30" v-slot="{ errors }" slim>
									<div class="label_and_element_wrapper">
										<label>
											Pickup Street
											<span>*</span>
										</label>
										<input type="text" v-model="address.pickup_address_1" ref='pickup_address_1'
											@focus="fetchLocationApi('pickup_address_1')"
											placeholder="Enter Character Limit 60"
											:class="errors[0] ? 'warning-border' : ''" :maxlength="max2" />

									</div>
								</ValidationProvider>
							</td>
							<td>
								<ValidationProvider name="name" rules="required|max:30" v-slot="{ errors }" slim>
									<div class="label_and_element_wrapper">
										<label>
											Floor Number
											<span>*</span>
										</label>
										<input type="text" v-model="address.floor"
											placeholder="Enter Character Limit 60"
											:class="errors[0] ? 'warning-border' : ''" :maxlength="max2" />

									</div>
								</ValidationProvider>
							</td>
						</tr>
						<tr>
							<td>
								<ValidationProvider name="name" rules="required|max:30" v-slot="{ errors }" slim>
									<div class="label_and_element_wrapper">
										<label>
											Desk Number
										</label>
										<input type="text" placeholder="Enter Character Limit 30"
											v-model="address.desk" :class="errors[0] ? 'warning-border' : ''"
											:maxlength="max2" />
									</div>
								</ValidationProvider>
							</td>
							<td>
								<ValidationProvider name="name" rules="required|max:30" v-slot="{ errors }" slim>
									<div class="label_and_element_wrapper">
										<label>
											Pickup Address 2

										</label>
										<input type="text" placeholder="Enter Character Limit 30"
											v-model="address.pickup_address_2" :class="errors[0] ? 'warning-border' : ''"
											:maxlength="max2" />
									</div>
								</ValidationProvider>
							</td>
						</tr>
						<tr>
							<td>
								<ValidationProvider name="name" rules="required|max:30" v-slot="{ errors }" slim>
									<div class="label_and_element_wrapper">
										<label>
											Pickup Suburb
											<span>*</span>
										</label>
										<input type="text" placeholder="Enter Character Limit 30"
											v-model="address.pickup_suburb" :class="errors[0] ? 'warning-border' : ''"
											:maxlength="max2" />
									</div>
								</ValidationProvider>
							</td>



							<td>
								<ValidationProvider name="name" rules="required|max:30" v-slot="{ errors }" slim>
									<div class="label_and_element_wrapper">
										<label>
											Pickup City

										</label>
										<input type="text" placeholder="Enter Character Limit 30"
											v-model="address.pickup_city" :class="errors[0] ? 'warning-border' : ''"
											:maxlength="max2" />
									</div>
								</ValidationProvider>
							</td>
						</tr>
						<tr>
							<td>
								<ValidationProvider name="name" rules="required|max:30" v-slot="{ errors }" slim>
									<div class="label_and_element_wrapper">
										<label>
											Pickup State
											<span>*</span>
										</label>
										<input type="text" placeholder="Enter Character Limit 30"
											v-model="address.pickup_state" :class="errors[0] ? 'warning-border' : ''"
											:maxlength="max2" />
									</div>
								</ValidationProvider>
							</td>




							<td>
								<ValidationProvider name="company name" rules="required|max:30" v-slot="{ errors }"
									slim>
									<div class="label_and_element_wrapper">
										<label>
											Pickup Postcode

										</label>
										<input type="text" placeholder="Enter Character Limit 10"
											v-model="address.pickup_postal_code" :maxlength="max2"
											:class="errors[0] ? 'warning-border' : ''" />
									</div>
								</ValidationProvider>
							</td>
						</tr>
						<tr>
							<td>
								<ValidationProvider name="address1" rules="required|max:60" v-slot="{ errors }" slim>
									<div class="label_and_element_wrapper">
										<label>
											Pickup Country
											<span>*</span>
										</label>
										<!-- <input type="text" placeholder="Enter Character Limit 30"
											v-model="address.pickup_country" :maxlength="max2"
											:class="errors[0] ? 'warning-border' : ''" /> -->
                                        <select-dropdown
                                            v-if="countries && countries.length > 0"
                                            :id="address.pickup_country_code"
                                            :items="countries"
                                            @setSelected="setSelectedCountry">
                                        </select-dropdown>
									</div>
								</ValidationProvider>
							</td>
						</tr>
					</table>
				</div>
			</section>

		</ValidationObserver>
		<section class="centered">
			<a class="link_bt bt_cancel" @click="closeModal">Cancel</a>
			<a class="link_bt bt_save" @click="submit">OK</a>
		</section>
	</div>
</template>
<script>
export default {
	name: "ChangePickupAddress",
    props: {
        countries: Array,
    },
	data() {
		return {
			max: 60,
			max2: 30,
			max1: 10,
			max4: 20,
			modalStatus: false,
			address: {
				floor: '',
				desk: '',
				pickup_address_1: "",
				pickup_address_2: "",
				pickup_suburb: "",
				pickup_city: "",
				pickup_state: "",
				pickup_postal_code: "",
				pickup_country: "",
				pickup_country_code: "",
			},
			pickupaddress: "",
		};
	},
	created() {

	},
	methods: {
		fetchLocationApi(type) {
			// return;
			let options = {

				types: ["geocode"]
			};
			let map = new google.maps.places.Autocomplete(this.$refs[type], {
				componentRestrictions: { country: [] },
				fields: ["address_components", "geometry"],
				types: ["address"],
			});
			map.addListener("place_changed", () => {
				let place = map.getPlace();

				this.address.pickup_address_1 = this.address.pickup_postal_code = '';
				for (const component of place.address_components) {
					const componentType = component.types[0];

					switch (componentType) {

						case "street_number": {
							this.address.pickup_address_1 = component.short_name;
							break;
						}
						// case "administrative_area_level_2": {
						//   this.draft.delivery_city = component.short_name;
						//   break;
						// }

						case "route": {
							this.address.pickup_address_1 = `${this.address.pickup_address_1} ${component.long_name}`;
							break;
						}

						case "postal_code": {
							this.address.pickup_postal_code = component.long_name;
							//  this.draft.postcode = `${component.long_name}${postcode}`;
							break;
						}

						case "postal_code_suffix": {
							this.address.postcode = `${this.address.pickup_postal_code}-${component.long_name}`;
							break;
						}
						case "locality": {
							this.address.pickup_city = component.short_name;
							this.address.pickup_suburb = component.short_name;
							break;
						}

						case "administrative_area_level_1": {
							this.address.pickup_state = component.short_name;
							break;
						}
						case "country":
							this.address.pickup_country = component.long_name;
							this.address.pickup_country_code = component.short_name;
							break;
					}

				}

			});
		},
		closeModal() {
			this.modalStatus = false;
			this.$emit("closeModal", this.modalStatus);
		},
		submit() {
			this.pickupaddress = {
				pickup_floor: this.address.floor,
				pickup_desk: this.address.desk,
				pickup_address_1: this.address.pickup_address_1,
				pickup_address_2: this.address.pickup_address_2,
				pickup_suburb: this.address.pickup_suburb,
				pickup_city: this.address.pickup_city,
				pickup_state: this.address.pickup_state,
				pickup_postal_code: this.address.pickup_postal_code,
				pickup_country: this.address.pickup_country,
				pickup_country_code: this.address.pickup_country_code,
			};

			if (this.pickupaddress.pickup_address_1 != '') {

				this.$emit("submit", this.pickupaddress);
				this.closeModal();

			} else {

				this.toast.error("Pickup Address must not be empty");
			}
		},
        setSelectedCountry(value) {
            this.address.pickup_country = '';
            this.address.pickup_country_code = '';
			if (typeof value !== 'undefined' && value != null) {
				this.address.pickup_country = typeof value.label !== 'undefined' ? value.label : value;
				this.address.pickup_country_code = typeof value.id !== 'undefined' ? value.id : value;
			}
        },
	},

};
</script>
<style lang="scss">
.popup_sub_container {
	padding: 20px;
	width: 100%;
	margin: auto;

	background: #fff;
}

.popup_sub_container>section {
	padding: 20px 0 0 0;
}

h3.sub_head {
	font-size: 20px;
	font-weight: 600;
	padding: 0;
}

.preferred_hide {
	display: none;
}

.chk_prefered {
	width: 20px;
	height: 20px;
	display: inline-block;
	margin: 0 20px;
}

.chk_prefered:checked+hr+.preferred_hide {
	display: block;
}

.title_image {
	display: block;
	margin: auto;
}

.form_section1 {
	width: 100%;
	height: auto;
	padding: 20px;
	background: #ececec;
	margin: 20px 0;
}

i.closeAddContact {
	width: 30px;
	height: 30px;
	background: #000;
	color: #06A5ED;
	font-size: 14px;
	text-align: center;
	line-height: 30px;
	border-radius: 100px;
	display: block;
	font-style: normal;
	font-weight: bold;
	position: relative;
	float: right;
	right: 0;
	top: -60px;
	cursor: pointer;
}
</style>
<style scoped>
/*this fix is for table with 2 column only, to keep same width*/
.form_section1 table tr td {
	width: 50%;
}
</style>