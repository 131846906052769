<template>
	<div class="popup_sub_container add_contact_adj">
		<h2>CHANGE GL CODE</h2>
		<i class="closeAddContact" @click="closeModal">X</i>
		<ValidationObserver ref="observer" v-slot="{ invalid }" slim>

			<section>

				<div class="form_section1">
					<table>
						<tr>
                            <td>
								<div class="label_and_element_wrapper">
                                    <label>
                                        Company
                                        <span>*</span>
                                    </label>
                                    <select-dropdown 
                                        :items="companies"
                                        :id="company_id"
                                        @setSelected="setSelectedCompany">
                                    </select-dropdown>
                                </div>
							</td>
						</tr>
						<tr>
							<td>
								<div class="label_and_element_wrapper">
                                    <label>
                                        Office
                                        <span>*</span>
                                    </label>
                                    <select-dropdown 
                                        :items="offices"
                                        :id="office_id"
                                        @setSelected="setSelectedOffice">
                                    </select-dropdown>
                                </div>
							</td>
						</tr>
						<tr>
							<td>
								<div class="label_and_element_wrapper">
                                    <label>
                                        Entity
                                        <span>*</span>
                                    </label>
                                    <select-dropdown 
                                        :items="entities"
                                        :id="entity_id"
                                        @setSelected="setSelectedEntity">
                                    </select-dropdown>
                                </div>
							</td>
						</tr>
						<tr>
							<td>
								<div class="label_and_element_wrapper">
                                    <label>
                                        Department
                                        <span>*</span>
                                    </label>
                                    <select-dropdown 
                                        :items="departments"
                                        :id="department_id"
                                        @setSelected="setSelectedDepartment">
                                    </select-dropdown>
                                </div>
							</td>
						</tr>
						<tr>
							<td>
								<div class="label_and_element_wrapper">
                                    <label>
                                        Section
                                        <span>*</span>
                                    </label>
                                    <select-dropdown 
                                        :items="sections"
                                        :id="section_id"
                                        @setSelected="setSelectedSection">
                                    </select-dropdown>
                                </div>
							</td>
						</tr>
					</table>
				</div>
			</section>

		</ValidationObserver>
		<section class="centered">
			<a class="link_bt bt_cancel" @click="closeModal">Cancel</a>
			<a class="link_bt bt_save" @click="submit">OK</a>
		</section>
	</div>
</template>
<script>
export default {
	name: "ChangeGlCode",
	data() {
		return {
			modalStatus: false,
            company_id: '',
            office_id: '',
            entity_id: '',
            department_id: '',
            section_id: '',
            offices: [{id: '', label: ''}],
            companies: [{id: '', label: ''}],
            entities: [{id: '', label: ''}],
            departments: [{id: '', label: ''}],
            sections: [{id: '', label: ''}],
            custom_gl_code: '',
		};
	},
	async created() {
        // this.fetchGlCodes();
        this.fetchGlCodesCompanies();
	},
	methods: {
        async fetchGlCodesCompanies() {
			try {
				await this.axios.get('/api/gl-code-companies')
                .then(async response => {
                    if (response) {
                        this.companies = [];
                        const companies = response.data.companies;
                        this.companies.push({
                            id: '',
                            label: 'Select Company',
                        });
                        companies.forEach((company) => {
                            this.companies.push({
                                id: company.code,
                                label: company.name,
                            });
                        });

                        this.company_id = '0';
                    }
                })
                .catch(error => {
                  console.log(error);
                });
			}
			catch (err) {
				// console.log(err);
			}
		},
        setSelectedCompany(value) {
            this.company_id = '';
			if (typeof value !== 'undefined' && value != null) {
                this.company_id = typeof value.id !== 'undefined' ? value.id : value;
                //fetch company offices
                this.axios.get('/api/gl-code-company-offices/'+value.id)
                .then(response => {
                    if (response) {
                        this.offices = [];
                        const offices = response.data.offices;
                        this.offices.push({
                            id: 0,
                            label: 'Select Office',
                        });

                        offices.forEach((office) => {
                            this.offices.push({
                                id: office.code,
                                label: office.code + ' - ' + office.name,
                            });
                        });
                    }
                })
                .catch(error => {
                    //console.log(error);
                });
			} else {
                this.offices = this.entities = this.departments = this.sections = [{id: '', label: ''}];
            }
            this.office_id = this.entity_id = this.department_id = this.section_id = 0;
		},
        setSelectedOffice(value) {
            this.office_id = '';
			if (typeof value !== 'undefined' && value != null) {
                this.office_id = typeof value.id !== 'undefined' ? value.id : value;
                //fetch company entities
                this.axios.get('/api/gl-code-office-entities/'+value.id)
                .then(response => {
                    if (response) {
                        this.entities = [];
                        const entities = response.data.entities;
                        this.entities.push({
                            id: 0,
                            label: 'Select Entity',
                        });

                        entities.forEach((entity) => {
                            this.entities.push({
                                id: entity.code,
                                label: entity.code + ' - ' + entity.name,
                            });
                        });
                    }
                })
                .catch(error => {
                  console.log(error);
                });
			} else {
                this.entities = this.departments = this.sections = [{id: '', label: ''}];
            }
            this.entity_id = this.department_id = this.section_id = 0;
		},
        setSelectedEntity(value) {
            this.entity_id = '';
			if (typeof value !== 'undefined' && value != null) {
                this.entity_id = typeof value.id !== 'undefined' ? value.id : value;
                //fetch company offices
                this.axios.get('/api/gl-code-entity-departments/'+value.id)
                .then(response => {
                    if (response) {
                        this.departments = [];
                        const departments = response.data.departments;
                        this.departments.push({
                            id: 0,
                            label: 'Select Department',
                        });

                        departments.forEach((department) => {
                            this.departments.push({
                                id: department.code,
                                label: department.code + ' - ' + department.name,
                            });
                        });
                    }
                })
                .catch(error => {
                //   console.log(error);
                });
			} else {
                this.departments = this.sections = [{id: '', label: ''}];
            }
            this.department_id = this.section_id = 0;
		},
        setSelectedDepartment(value) {
            this.department_id = '';
			if (typeof value !== 'undefined' && value != null) {
                this.department_id = typeof value.id !== 'undefined' ? value.id : value;
                //fetch company offices
                this.axios.get('/api/gl-code-department-sections/'+value.id)
                .then(response => {
                    if (response) {
                        this.sections = [];
                        const sections = response.data.sections;
                        this.sections.push({
                            id: 0,
                            label: 'Select Sections',
                        });

                        sections.forEach((section) => {
                            this.sections.push({
                                id: section.code,
                                label: section.code + ' - ' + section.name,
                            });
                        });
                    }
                })
                .catch(error => {
                    //console.log(error);
                });
			} else {
                this.sections = [{id: '', label: ''}];
            }
            this.section_id = 0;
		},
        setSelectedSection(value) {
            this.section_id = '';
			if (typeof value !== 'undefined' && value != null) {
                this.section_id = typeof value.id !== 'undefined' ? value.id : value;
                // this.custom_gl_code = this.office_id+'-'+this.entity_id+'-'+this.department_id+'-'+this.section_id+'-000';
                this.custom_gl_code = this.section_id+'-'+this.department_id+'-'+this.office_id+'-'+this.entity_id+'-000';
			}
		},
		closeModal() {
			this.modalStatus = false;
			this.$emit("closeModal", this.modalStatus);
		},
		submit() {
			this.$emit("submit", this.custom_gl_code);
			this.closeModal();
		},
	},

};
</script>
<style lang="scss">
.popup_sub_container {
	padding: 20px;
	width: 100%;
	margin: auto;

	background: #fff;
}

.popup_sub_container>section {
	padding: 20px 0 0 0;
}

h3.sub_head {
	font-size: 20px;
	font-weight: 600;
	padding: 0;
}

.preferred_hide {
	display: none;
}

.chk_prefered {
	width: 20px;
	height: 20px;
	display: inline-block;
	margin: 0 20px;
}

.chk_prefered:checked+hr+.preferred_hide {
	display: block;
}

.title_image {
	display: block;
	margin: auto;
}

.form_section1 {
	width: 100%;
	height: auto;
	padding: 20px;
	background: #ececec;
	margin: 20px 0;
}

i.closeAddContact {
	width: 30px;
	height: 30px;
	background: #000;
	color: #06A5ED;
	font-size: 14px;
	text-align: center;
	line-height: 30px;
	border-radius: 100px;
	display: block;
	font-style: normal;
	font-weight: bold;
	position: relative;
	float: right;
	right: 0;
	top: -60px;
	cursor: pointer;
}
</style>
<style scoped>
/*this fix is for table with 2 column only, to keep same width*/
.form_section1 table tr td {
	width: 50%;
}
</style>